<template>
  <vx-card class="edit-bank-page" :class="{'main-box md:w-1/2 top-zero-radius': bankId === 0, 'in-modal': bankId > 0}">
    <vs-input class="w-full"
              :label="`${$t('treasury.banks.labels.name')}`"
              :danger="validation.name.length > 0"
              :danger-text="validation.name"
              v-model="bank.name"/>

    <vs-input class="w-full dir-rtl"
              :label="`${$t('treasury.banks.labels.accountNumber')}`"
              @input="bank.account_number = isNumber($event)"
              :danger="validation.account_number.length > 0"
              :danger-text="validation.account_number"
              v-model="bank.account_number"/>

    <vs-input class="w-full dir-rtl"
              :label="`${$t('treasury.banks.labels.cardNumber')}`"
              @input="bank.card_number = isNumber($event)"
              :danger="validation.card_number.length > 0"
              :danger-text="validation.card_number"
              v-model="bank.card_number"/>

    <vs-input class="w-full dir-rtl"
              :label="`${$t('treasury.banks.labels.shebaNumber')}`"
              @input="bank.shaba_number = isNumber($event)"
              :danger="validation.shaba_number.length > 0"
              :danger-text="validation.shaba_number"
              v-model="bank.shaba_number"/>

    <vs-button id="editBankBTN"
               class="useral-action-button"
               @click="sendData()"/>

    <vs-button id="deleteBankBTN"
               v-if="checkUserPermissions('bank.delete')"
               class="w-full mt-5"
               color="danger"
               @click="$refs.deleteConfirmation.showDialog()">{{$t('treasury.banks.labels.delete')}}</vs-button>

    <custom-dialog ref="deleteConfirmation"
                   :title="`${$t('treasury.banks.edit.confirmations.delete.title')}`"
                   :body="`${$t('treasury.banks.edit.confirmations.delete.body', {bank: bank.name})}`"
                   @accept="deleteBank"/>
  </vx-card>
</template>

<script>
import axios from 'axios'
import {deleteBank, editBank, getBank} from '@/http/requests/banks'
import CustomDialog from '@/components/customDialog/customDialog'
import {checkUserPermissions} from "../../../../../../assets/js/functions";

export default {
  name: 'editBank',
  components: {CustomDialog},
  metaInfo () {
    return {
      title: !this.bank.name ? this.$t('treasury.banks.edit.title') : this.$t('treasury.banks.edit.dynamicTitle', {bank: this.bank.name})
    }
  },
  props: {
    bankId: {
      type: Number,
      default: () => { return 0 }
    }
  },
  data () {
    return {
      firstChange: true,
      bank: {},
      bankCopy: {},
      validation: {
        name: '',
        account_number: '',
        card_number: '',
        shaba_number: ''
      },
      actions: [
        {
          toolbar: [
            {
              id: 'editBankBTN',
              icon: 'icon-check',
              iconPack: 'feather',
              color: 'success'
            }
          ],
          leftToolbar: [
            {
              id: {name: 'treasuryBank', params: {id: this.$route.params.id}},
              type: 'link',
              icon: 'icon-chevron-left',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {
    if (this.bankId === 0) {
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')
      }, 50)
    }

    this.getBank()
  },
  methods: {
    addDash (val) {
      let sign = ''

      if (val < 0) sign = '-'

      val = val.toString()

      if (val[0] === '0') val = val.substr(1, val.length - 1)

      return val.toString().replace(/\D/g, '')
        .replace(/\B(?=(\d{4})+(?!\d))/g, '-') + sign
    },
    checkUserPermissions(permission) {
      return checkUserPermissions(permission)
    },
    isNumber (val) {
      return val.toString().replace(/[^0-9]/g, '')
    },
    getBank () {
      getBank(this.bankId || this.$route.params.id).then(response => {
        this.bank = response.data.data
        this.bankCopy = JSON.parse(JSON.stringify(response.data.data))
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })
    },
    sendData () {
      this.nameValidator()
      this.accountNumberValidator()
      this.cardNumberValidator()
      this.shabaNumberValidator()

      if (!this.validation.name &&
        !this.validation.account_number &&
        !this.validation.card_number &&
        (!this.validation.shaba_number || !this.bank.shaba_number)) {
        const bank = this.bank

        if (this.bankCopy.name === bank.name) delete bank.name

        if (this.bankCopy.account_number === bank.account_number) delete bank.account_number

        if (this.bankCopy.card_number === bank.card_number) delete bank.card_number
        else bank.card_number = bank.card_number.replaceAll('-', '')

        if (this.bankCopy.shaba_number === bank.shaba_number) delete bank.shaba_number

        editBank(bank).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('treasury.banks.edit.notifications.edit.success'),
            time: 2400,
            icon: 'icon-check',
            iconPack: 'feather',
            color: 'success'
          })

          if (this.bankId === 0) {
            this.$store.dispatch('removePageChanges')
            this.$router.push({name: 'treasuryBank', params: {id: this.$route.params.id}})
          } else {
            this.$emit('edited')
          }
        }).catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('treasury.banks.edit.notifications.edit.error'),
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              color: 'danger'
            })
          }
        })
      }
    },
    deleteBank () {
      deleteBank(this.bankId || this.$route.params.id).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.banks.edit.notifications.delete.success'),
          time: 2400,
          icon: 'icon-check',
          iconPack: 'feather',
          color: 'success'
        })

        if (this.bankId === 0) {
          this.$store.dispatch('removePageChanges')
          this.$router.push({name: 'treasuryBanks'})
        } else {
          this.$emit('edited')
        }
      }).catch(error => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.banks.edit.notifications.delete.error'),
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            color: 'danger'
          })
        }
      })
    },
    nameValidator () {
      this.validation.name = (this.bank.name || this.bankCopy.name).toString().length >= 2 ? '' : 'نام بانک به صورت صحیح وارد نشده است'
    },
    accountNumberValidator () {
      this.validation.account_number = (this.bank.account_number || this.bankCopy.account_number).toString().length >= 6 ? '' : 'شماره حساب به صورت صحیح وارد نشده است'
    },
    cardNumberValidator () {
      const card = (this.bank.card_number || this.bankCopy.card_number).toString().replaceAll('-', '')
      if (typeof card === 'undefined'
        || card === null
        || card.length !== 16) {
        this.validation.card_number = this.$t('treasury.banks.validators.cardNumber')
      } else {
        this.validation.card_number = ''
      }
    },
    shabaNumberValidator () {
      this.validation.shaba_number = this.$validator('regex.treasury.bank.shabaNumber').test(this.bank.shaba_number || this.bankCopy.shaba_number) ? '' : this.$t('treasury.banks.validators.shebaNumber')
    }
  },
  watch: {
    'bank': {
      handler (val, oldVal) {

        if (!this.firstChange) this.$store.dispatch('setPageChanges')

        if (Object.keys(oldVal).length > 0 && JSON.stringify(val)) {
          this.firstChange = false
        }
      },
      deep: true
    },
    'bank.name': {
      handler () {
        this.nameValidator()
      }
    },
    'bank.account_number': {
      handler () {
        this.accountNumberValidator()
      }
    },
    'bank.card_number': {
      handler () {
        this.cardNumberValidator()

        // this.bank.card_number = this.addDash(this.bank.card_number)
        if (this.bank.card_number.length > 16) {
          this.bank.card_number = this.bank.card_number.toString().substr(0, 16)
        }
      }
    },
    'bank.shaba_number': {
      handler () {
        this.shabaNumberValidator()

        if (this.bank.shaba_number.length > 24) {
          this.bank.shaba_number = this.bank.shaba_number.toString().substr(0, 24)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.edit-bank-page {

  &.in-modal {
    box-shadow: none;

    .vx-card__body {
      padding: 0.5rem;
    }
  }

  .dir-rtl {

    input {
      direction: rtl;
    }
  }
}
</style>

